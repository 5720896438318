import request from '@capturi/request'
import { useQueryClient } from '@tanstack/react-query'
import { mutate } from 'swr'

import { useMemo } from 'react'
import { Mode, UserScheduledForDeletion } from '../types'

const baseUrl = 'user/deleted?api-version=3.3'
const queryKey = [baseUrl]

export const useDeletedUsers = (): {
  markUserForDeletion: (
    userUid: string,
    when: Date,
    mode: Mode,
  ) => Promise<void>
  cancelUserDeletion: (userUid: string) => Promise<void>
} => {
  const queryClient = useQueryClient()

  return useMemo(() => {
    const markUserForDeletion = async (
      userUid: string,
      when: Date,
      mode: Mode,
    ): Promise<void> => {
      const previousUsers = queryClient.getQueryData<{
        users: UserScheduledForDeletion[]
      }>(queryKey)
      try {
        queryClient.setQueryData<
          { users: UserScheduledForDeletion[] } | undefined
        >(queryKey, (o) =>
          o
            ? {
                users: o?.users.map((u) =>
                  u.userUid === userUid ? { ...u, mode, when } : u,
                ),
              }
            : undefined,
        )

        await request.post(
          `user/deleted/${userUid}/mark?when=${when.toISOString()}&mode=${mode}&api-version=3.3`,
        )
      } catch (error) {
        queryClient.setQueriesData<
          { users: UserScheduledForDeletion[] } | undefined
        >({ queryKey }, previousUsers)
        throw error
      } finally {
        queryClient.invalidateQueries({
          queryKey,
        })
        await mutate('user/deleted?api-version=3.3')
      }
    }

    const cancelUserDeletion = async (userUid: string): Promise<void> => {
      const previousUsers = queryClient.getQueryData<{
        users: UserScheduledForDeletion[]
      }>(queryKey)
      try {
        queryClient.setQueryData<
          { users: UserScheduledForDeletion[] } | undefined
        >(queryKey, (o) =>
          o
            ? {
                users: o?.users.filter((u) => u.userUid !== userUid),
              }
            : undefined,
        )
        await request.delete(`user/deleted/${userUid}/unmark?api-version=3.3`)
      } catch (error) {
        queryClient.setQueriesData<
          { users: UserScheduledForDeletion[] } | undefined
        >({ queryKey }, previousUsers)
        throw error
      } finally {
        queryClient.invalidateQueries({
          queryKey,
        })
        await mutate('user/deleted?api-version=3.3')
      }
    }

    return {
      markUserForDeletion,
      cancelUserDeletion,
    }
  }, [queryClient])
}
