import { Button } from '@capturi/ui-components'
import { Flex, Icon, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { MdArrowDownward, MdInfo } from 'react-icons/md'

type Props = {
  isActive: boolean
  direction: 1 | -1
  onClick: () => void
  tooltip?: React.ReactNode
  ariaLabel?: string
  children?: React.ReactNode
}

const TableHeader: React.FC<Props> = ({
  direction,
  isActive,
  onClick,
  tooltip,
  ariaLabel,
  children,
}) => {
  return (
    <Flex>
      <Button
        onClick={onClick}
        color="gray.800"
        variant="link"
        leftIcon={
          isActive ? (
            <MdArrowDownward
              style={{
                transform: direction === 1 ? undefined : 'rotate(-0.5turn)',
                transition: 'all 0.3s',
              }}
            />
          ) : undefined
        }
      >
        {children}
      </Button>
      {!!tooltip && (
        <Tooltip
          shouldWrapChildren
          hasArrow
          label={tooltip}
          aria-label={ariaLabel}
          placement="bottom-end"
        >
          <Icon as={MdInfo} ml={2} color="gray.400" />
        </Tooltip>
      )}
    </Flex>
  )
}

export default TableHeader
