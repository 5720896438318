import { useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'

import { BackSyncTeamModal } from '../components/modals/BackSyncTeamModal'

const useBackSyncTeam = (): ((
  userUid: string,
  name: string,
  teamUid: string,
  teamName: string,
) => void) => {
  const [openBackSyncTeamModal] = useModal(BackSyncTeamModal)
  const toast = useToast()

  const backSyncUserTeam = (
    userUid: string,
    name: string,
    teamUid: string,
    teamName: string,
  ): void => {
    openBackSyncTeamModal({
      name,
      teamName,
      teamUid,
      userUid,
      onSubmit: () => {
        toast({
          title: t`Moving the user’s conversation to ${teamName}. This operation might take a while.`,
          status: 'success',
        })
      },
    })
  }

  return backSyncUserTeam
}

export { useBackSyncTeam }
