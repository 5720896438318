import analytics from '@capturi/analytics'
import { conversationsAPI } from '@capturi/api-conversations'
import request, { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Flex, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { format } from 'date-fns/fp'
import React, { useEffect, useState } from 'react'

type Options = {
  name: string
  teamName: string
  teamUid: string
  userUid: string
  onSubmit: () => void
}

type Props = Options & BaseModalComponentProps
const toTime = format("yyyy-MM-dd'T'HH:mm")

export const BackSyncTeamModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  name,
  userUid,
  teamName,
  teamUid,
}) => {
  const [date, setDate] = useState<string>(toTime(new Date()))
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [moveConversationsValue, setMoveConversationsValue] =
    React.useState<string>('noDate')
  const [error, setError] = useState<string>('')

  const handleSync = async (): Promise<void> => {
    try {
      setInProgress(true)
      analytics.event('organization_teams_roleSync', {
        fromDate: new Date(date),
      })

      await request<{ conversationsChanged: number }>(
        conversationsAPI.syncTeamOnConversations(
          userUid,
          teamUid,
          new Date(date),
        ),
      )

      onSubmit?.()
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      setInProgress(false)
      if (errorMsg) setError(errorMsg)
    }
  }

  useEffect(() => {
    if (moveConversationsValue === 'noDate') {
      setDate(toTime(new Date('2020-01-01T07:00')))
    }
    if (moveConversationsValue === 'withDate') {
      setDate(toTime(new Date()))
    }
  }, [moveConversationsValue])

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Text>
              <Trans>Move conversations to team {teamName}</Trans>
            </Text>
          </ModalHeader>
          <ModalCloseButton isDisabled={inProgress} />
          <ModalBody>
            <RadioGroup
              id="move-conversations"
              onChange={setMoveConversationsValue}
              value={moveConversationsValue}
            >
              <Stack>
                <Radio value="noDate" cursor="pointer">
                  <Trans>Move all conversations</Trans>
                </Radio>
                <Radio value="withDate" cursor="pointer">
                  <Trans>Move only conversations in specific time period</Trans>
                </Radio>
              </Stack>
            </RadioGroup>
            {moveConversationsValue === 'withDate' && (
              <Flex mt={2} alignItems="center">
                <Text color="gray.600">
                  <Trans>Move all {`${name}'s`} conversations since</Trans>
                </Text>
                <Input
                  type="datetime-local"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  isDisabled={inProgress}
                  width="fit-content"
                  mx="2"
                  size="sm"
                />
              </Flex>
            )}

            {error && <Text color="danger">{error}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={inProgress} secondary onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button ml="2" isLoading={inProgress} primary onClick={handleSync}>
              <Trans>Move conversations</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
