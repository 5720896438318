import analytics from '@capturi/analytics'
import { Button, useActionToast } from '@capturi/ui-components'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Card,
  Center,
  Divider,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import { useMergeUser } from 'pages/Organization/hooks/useMergeUser'
import { useUsers } from 'pages/Organization/hooks/useUsers'
import React, { useState } from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import MergeUser from './MergeUser'

type Options = {
  userUid: string
}

type Props = Options & BaseModalComponentProps

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}
const MergeUsersDialog: React.FC<Props> = ({ onClose, userUid }) => {
  const toast = useToast()

  const successUndoToast = useActionToast({
    status: 'success',
    actionText: t`Undo`,
  })

  const { users } = useUsers()
  const { mutate: mergeUser } = useMergeUser()
  const { hideInviteOptions } = useFeatureFlags()

  const currentUser = users?.find((u) => u.id === userUid)

  const [targetUser, setTargetUser] = useState<SelectOption | undefined>(
    currentUser
      ? {
          value: currentUser?.id,
          label: currentUser?.name,
        }
      : undefined,
  )
  const [sourceUser, setSourceUser] = useState<SelectOption | undefined>(
    undefined,
  )

  //Creates a promise based timeout to await user input before either completing user merge or cancelling.
  const delay = (ms: number): Promise<unknown> =>
    new Promise((res) => setTimeout(res, ms))

  const selectedSourceUser = users?.find((u) => u.id === sourceUser?.value)
  const selectedTargetUser = users?.find((u) => u.id === targetUser?.value)

  const [sourceConversationCount, setSourceConversationCount] =
    useState<number>(0)

  const [targetConversationCount, setTargetConversationCount] =
    useState<number>(0)

  const [isCountLoading, setIsCountLoading] = useState<boolean>(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean | undefined>(
    undefined,
  )
  const sourceUserOptions = React.useMemo(() => {
    return (users ?? [])
      ?.filter((u) => u.id !== selectedTargetUser?.id)
      .map<SelectOption>((user) => ({
        value: user.id,
        label: user.name,
      }))
  }, [selectedTargetUser?.id, users])

  const targetUserOptions = React.useMemo(() => {
    return (users ?? [])
      ?.filter((u) => u.id !== sourceUser?.value)
      .map<SelectOption>((user) => ({
        value: user.id,
        label: user.name,
      }))
  }, [sourceUser?.value, users])

  const handleSelectSourceUser = React.useCallback(
    (option: OnChangeValue<SelectOption, false>): void => {
      if (option === null) return
      setSourceUser(option)
    },
    [],
  )

  const handleSelectTargetUser = React.useCallback(
    (option: OnChangeValue<SelectOption, false>): void => {
      if (option === null) return
      setTargetUser(option)
    },
    [],
  )

  const getTotalConversationCount = (
    sourceCount: number,
    targetCount: number,
  ): number | undefined => {
    return sourceCount + targetCount
  }

  const handleMergeUsers = async (
    targetUserUid: string | undefined,
    sourceUserUids: string | undefined,
  ): Promise<void> => {
    setIsSubmitLoading(true)
    let cancelMerge = false
    successUndoToast({
      title: t`Users merged!`,
      description: t`${selectedSourceUser?.name} and ${selectedTargetUser?.name} have been successfully merged!`,
      status: 'success',
      onActionClick: () => {
        cancelMerge = true
        setIsSubmitLoading(false)
        toast({
          title: t`Merge cancelled`,
          description: t`The merge of ${selectedSourceUser?.name} and ${selectedTargetUser?.name} was cancelled.`,
          status: 'info',
        })
      },
    })
    await delay(5000)
    if (cancelMerge) {
      setIsSubmitLoading(false)
      return
    }
    await mergeUser(
      { targetUserUid, sourceUserUids },
      {
        onSuccess: () => {
          handleOnClose()
          analytics.event('merge_user_submit_success')
          setIsSubmitLoading(false)
        },
        onError: () => {
          toast({
            title: t`An error occurred. Please try again`,
            description: t`Something went wrong`,
            status: 'error',
          })
          analytics.event('merge_user_submit_error')
          setIsSubmitLoading(false)
        },
      },
    )
  }

  const handleOnClose = (): void => {
    onClose()
    analytics.event('merge_user_dialog_close')
  }

  return (
    <Modal size="4xl" isOpen onClose={handleOnClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Trans>
              Merge {selectedTargetUser?.name} with another user/agent
            </Trans>
          </ModalHeader>
          <Divider w="100%" />
          <ModalBody>
            <Stack direction={['column', 'row']} spacing="24px" mb={2}>
              <MergeUser
                user={targetUser}
                userOptions={targetUserOptions}
                selectedUser={selectedTargetUser}
                currentUser={currentUser}
                handleSelectUser={handleSelectTargetUser}
                setConversationCount={setTargetConversationCount}
                setIsCountLoading={setIsCountLoading}
              />
              <Flex>
                <VStack>
                  <Divider orientation="vertical" />
                  <Box as="span">
                    <Icon boxSize={6} as={MdKeyboardBackspace} />
                  </Box>
                  <Divider orientation="vertical" />
                </VStack>
              </Flex>
              <MergeUser
                user={sourceUser}
                userOptions={sourceUserOptions}
                selectedUser={selectedSourceUser}
                currentUser={currentUser}
                handleSelectUser={handleSelectSourceUser}
                setConversationCount={setSourceConversationCount}
                setIsCountLoading={setIsCountLoading}
              />
            </Stack>
            {selectedSourceUser && selectedTargetUser && (
              <>
                {selectedSourceUser.inviteStatus === 'accepted' && (
                  <Alert status="error" mb={4}>
                    <AlertIcon />
                    <AlertDescription>
                      {hideInviteOptions ? (
                        <Trans>
                          Merging with an already authenticated user is not
                          allowed. Please select another user.
                        </Trans>
                      ) : (
                        <Trans>
                          Please note that {selectedSourceUser.name} will no
                          longer be able to login to Capturi.
                        </Trans>
                      )}
                    </AlertDescription>
                  </Alert>
                )}
                <Stack
                  backgroundColor="gray.100"
                  alignItems="center"
                  py={6}
                  boxShadow="0 -3px 7px -5px #757575"
                >
                  <Card
                    display="flex"
                    px={6}
                    py={3}
                    w="65%"
                    backgroundColor="white"
                    border="2px dashed"
                    borderColor="gray.400"
                    boxShadow="base"
                  >
                    <Stack direction={['column', 'row']} spacing="24px">
                      {selectedSourceUser && (
                        <>
                          <VStack align="stretch" w="65%">
                            <Text fontWeight="500" fontSize="15px" mb={4}>
                              <Trans>Merged user</Trans>
                            </Text>
                            <HStack mb={3}>
                              {currentUser && (
                                <UserAvatar
                                  name={selectedTargetUser.name}
                                  profileImage={currentUser.profileImage}
                                  size="sm"
                                />
                              )}
                              <Text fontWeight="500" fontSize="15px">
                                {selectedTargetUser.name}
                              </Text>
                            </HStack>
                            <VStack alignItems="flex-start">
                              <List>
                                <ListItem>
                                  <Trans>E-mail</Trans>{' '}
                                  <Text as="span" fontWeight="500">
                                    {selectedTargetUser.email}
                                  </Text>
                                </ListItem>

                                <ListItem>
                                  <Trans>Role</Trans>{' '}
                                  <Text as="span" fontWeight="500">
                                    {selectedTargetUser.role}
                                  </Text>
                                </ListItem>
                                <ListItem>
                                  <Trans>Log-in status</Trans>{' '}
                                  <Text as="span" fontWeight="500">
                                    <Trans>Last login</Trans>{' '}
                                    {selectedTargetUser.lastTokenRefresh
                                      ? i18n.date(
                                          selectedTargetUser.lastTokenRefresh as
                                            | string
                                            | Date,
                                          dateFormatOptions,
                                        )
                                      : t`N/A`}
                                  </Text>
                                </ListItem>
                                <ListItem>
                                  <Trans>Team</Trans>{' '}
                                  <Text as="span" fontWeight="500">
                                    {selectedTargetUser.team?.name ??
                                      t`No team`}
                                  </Text>
                                </ListItem>
                              </List>
                            </VStack>
                          </VStack>
                          <Center>
                            <Divider orientation="vertical" />
                          </Center>
                          <VStack w="35%" justifyContent="center">
                            {isCountLoading ? (
                              <Stack isInline align="center">
                                <Skeleton
                                  display={['none', null, null, 'block']}
                                  h={5}
                                  w={16}
                                />
                              </Stack>
                            ) : (
                              <Text mt={5} fontWeight="500">
                                {getTotalConversationCount(
                                  sourceConversationCount,
                                  targetConversationCount,
                                )}{' '}
                                <Trans>Conversations</Trans>
                              </Text>
                            )}
                          </VStack>
                        </>
                      )}
                    </Stack>
                  </Card>
                </Stack>
              </>
            )}
            {selectedSourceUser && (
              <Text mt={2} color="gray.600">
                ⚠️{' '}
                <Trans>
                  When the users are merged, you&apos;ll only be able to see the
                  user above
                </Trans>
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Stack isInline spacing={4}>
              <Button secondary onClick={handleOnClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Tooltip
                hasArrow
                placement="top"
                label={t`Please select a target user to merge with`}
                isDisabled={!!sourceUser}
              >
                <Button
                  isDisabled={
                    !sourceUser ||
                    (selectedSourceUser?.inviteStatus === 'accepted' &&
                      hideInviteOptions)
                  }
                  isLoading={isSubmitLoading}
                  primary
                  onClick={() =>
                    handleMergeUsers(
                      selectedTargetUser?.id,
                      selectedSourceUser?.id,
                    )
                  }
                >
                  <Trans>Merge users</Trans>
                </Button>
              </Tooltip>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default MergeUsersDialog
