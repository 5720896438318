import { Button } from '@capturi/ui-components'
import {
  Box,
  Circle,
  Flex,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { differenceInDays, subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { MdFilterList } from 'react-icons/md'

export type DateFilterKey = 'mostRecentConversation' | 'lastTokenRefresh'

type Props = {
  setDateFilter: React.Dispatch<
    React.SetStateAction<{
      key: DateFilterKey
      date: Date
    } | null>
  >
  dateFilter: {
    key: DateFilterKey
    date: Date
  } | null
  filterOptions: DateFilterKey[]
}
const DEFAULT_DAYS = 100
const Filter: React.FC<Props> = ({
  setDateFilter,
  dateFilter,
  filterOptions,
}) => {
  const [key, setKey] = useState<DateFilterKey>('mostRecentConversation')
  const [days, setDays] = useState<number>(DEFAULT_DAYS)

  useEffect(() => {
    if (dateFilter) {
      setKey(dateFilter.key)
      setDays(differenceInDays(new Date(), dateFilter.date))
    }
  }, [dateFilter])

  return (
    <Popover placement="bottom-start" isLazy>
      {({ onClose }) => (
        <>
          <Tooltip hasArrow label={t`Filter Users`} placement="top">
            <Box display="inline-block">
              <PopoverTrigger>
                <Box position="relative">
                  <IconButton
                    borderRadius={4}
                    icon={<Icon as={MdFilterList} />}
                    size="sm"
                    variant="solid"
                    aria-label="filter icon"
                  />
                  {dateFilter != null && (
                    <Circle
                      border="1px solid white"
                      background="success"
                      position="absolute"
                      size="7px"
                      right="8px"
                      top="7px"
                    />
                  )}
                </Box>
              </PopoverTrigger>
            </Box>
          </Tooltip>
          <Portal>
            <PopoverContent width="fit-content">
              <Flex p="4" alignItems="center">
                {filterOptions.length === 1 ? (
                  <Text>
                    {filterOptions[0] === 'lastTokenRefresh' ? (
                      <Trans>Last Activity</Trans>
                    ) : (
                      <Trans>Last Conversation</Trans>
                    )}
                  </Text>
                ) : (
                  <Select
                    onChange={(e) =>
                      setKey(e.currentTarget.value as DateFilterKey)
                    }
                    value={key}
                  >
                    <option value="mostRecentConversation">{t`Last Conversation`}</option>
                    <option value="lastTokenRefresh">{t`Last Activity`}</option>
                  </Select>
                )}
                <NumberInput
                  title="days ago"
                  min={1}
                  mx="4"
                  onChange={(e) => {
                    setDays(Number(e))
                  }}
                  value={days}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text whiteSpace="nowrap" mr="4">
                  <Trans>days ago</Trans>
                </Text>

                <Button
                  size="md"
                  onClick={() => {
                    setDateFilter(null)
                    setDays(DEFAULT_DAYS)
                    setKey('mostRecentConversation')
                    onClose()
                  }}
                  variant="ghost"
                >
                  <Trans>Reset</Trans>
                </Button>
                <Button
                  size="md"
                  variant="ghost"
                  primary
                  onClick={() => {
                    setDateFilter({
                      key: key,
                      date: subDays(new Date(), days || 0),
                    })
                    onClose()
                  }}
                >
                  <Trans>OK</Trans>
                </Button>
              </Flex>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}

export default Filter
