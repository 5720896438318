import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { UserResponse } from '../types'
import { queryKey } from './useUserActions'

export type MergeUserPayload = {
  conversations: number
}

export type MergeUserFormModel = {
  targetUserUid: string | undefined
  sourceUserUids: string | undefined
}

export const useMergeUser = (): UseMutationResult<
  MergeUserFormModel,
  ResponseError,
  MergeUserFormModel
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: queryKey,
    mutationFn: ({ targetUserUid, sourceUserUids }) =>
      request.post('user/merge?api-version=3.3', {
        json: {
          targetUserUid,
          sourceUserUids: [sourceUserUids],
        },
      }),
    onSuccess: (_, model) => {
      queryClient.setQueryData<{ users: UserResponse[] }>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return { users: [] }
          }
          return {
            users: oldData.users.filter((u) => u.id !== model.sourceUserUids),
          }
        },
      )
      queryClient.invalidateQueries({
        queryKey: ['global', 'users'],
      })
    },
  })
}
