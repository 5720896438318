import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import {
  UserResponse,
  UserScheduledForDeletion,
  UserWithDeletionData,
} from '../types'

export const useUsers = (): {
  users: UserWithDeletionData[] | undefined
} => {
  const { data: markedUsers } = useQuery<{ users: UserScheduledForDeletion[] }>(
    { queryKey: ['user/deleted?api-version=3.3'] },
  )
  const { data: users } = useQuery<{ users: UserResponse[] }>({
    queryKey: ['authentication/users?includeDeleted=false'],
  })

  const mappedUsers: UserWithDeletionData[] | undefined = useMemo(() => {
    const markedUserMap = new Map(
      (markedUsers?.users ?? []).map((u) => [u.userUid, u]),
    )

    return (
      markedUsers &&
      users &&
      (users.users ?? []).map((user) => {
        const markedUser = markedUserMap.get(user.id)

        if (markedUser) {
          return {
            ...user,
            isScheduledForDeletion: true,
            when: markedUser.when,
            mode: markedUser.mode,
          }
        }

        return {
          ...user,
          isScheduledForDeletion: false,
          when: undefined,
          mode: undefined,
        }
      })
    )
  }, [users, markedUsers])
  return {
    users: mappedUsers,
  }
}
