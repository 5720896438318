import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Box, HStack, List, ListItem, Text, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import { UserWithDeletionData } from 'pages/Organization/types'
import React from 'react'

import MergeUserConversationCount from '../MergeUserConversationCount'

type Props = {
  user: SelectOption | undefined
  userOptions: SelectOption[]
  selectedUser: UserWithDeletionData | undefined
  currentUser: UserWithDeletionData | undefined
  handleSelectUser: (option: OnChangeValue<SelectOption, false>) => void
  setConversationCount: (count: number) => void
  setIsCountLoading: (loading: boolean) => void
}

const MergeUser: React.FC<Props> = ({
  user,
  userOptions,
  selectedUser,
  currentUser,
  handleSelectUser,
  setConversationCount,
  setIsCountLoading,
}) => {
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  return (
    <Box w="50%" mb={4}>
      <Select value={user} options={userOptions} onChange={handleSelectUser} />
      {selectedUser && currentUser && (
        <VStack alignItems="flex-start">
          <HStack mb={3} justifyContent="flex-start" mt={8}>
            <UserAvatar
              name={selectedUser.name}
              profileImage={currentUser.profileImage}
              size="sm"
            />
            <Text fontWeight="500" fontSize="15px">
              {selectedUser.name}
            </Text>
          </HStack>
          <VStack>
            <MergeUserConversationCount
              userUid={selectedUser.id}
              onConversationChange={setConversationCount}
              onLoadingChange={setIsCountLoading}
            />
          </VStack>
          <VStack>
            <List>
              <ListItem>
                <Text>
                  <Trans>E-mail</Trans>{' '}
                  <Text as="span" fontWeight="500">
                    {selectedUser.email}
                  </Text>
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Trans>Role</Trans>{' '}
                  <Text as="span" fontWeight="500">
                    {selectedUser.role}
                  </Text>
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Trans>Log-in status</Trans>{' '}
                  <Text as="span" fontWeight="500">
                    <Trans>Last login</Trans>{' '}
                    {selectedUser.lastTokenRefresh
                      ? i18n.date(
                          selectedUser.lastTokenRefresh as string | Date,
                          dateFormatOptions,
                        )
                      : t`N/A`}
                  </Text>
                </Text>
              </ListItem>
              <ListItem>
                <Text>
                  <Trans>Team</Trans>{' '}
                  <Text as="span" fontWeight="500">
                    {selectedUser.team?.name ?? t`No team`}
                  </Text>
                </Text>
              </ListItem>
            </List>
          </VStack>
        </VStack>
      )}
    </Box>
  )
}

export default MergeUser
