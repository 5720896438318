import tracker from '@capturi/analytics'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useState } from 'react'
import isEmailValid from 'utils/isEmailValid'

type Options = {
  name: string
  currentEmail: string
  userUid: string
  invite: (userUid: string, email: string) => Promise<void>
  onSubmit: () => void
}

type Props = Options & BaseModalComponentProps

export const InviteUserFromEmailDialog: React.FC<Props> = ({
  onClose,
  onSubmit,
  currentEmail,
  name,
  invite,
  userUid,
}) => {
  const [email, setEmail] = useState<string>(currentEmail)
  const invalidEmail = !isEmailValid(email)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const handleInvite = async (): Promise<void> => {
    try {
      tracker.event('organizationPage_userInvited')

      setInProgress(true)

      await invite(userUid, email)

      setInProgress(false)
      onSubmit()
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      setInProgress(false)
      if (errorMsg) setError(errorMsg)
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Text>
              <Trans>Invite {name} to Capturi</Trans>
            </Text>
          </ModalHeader>
          <ModalCloseButton isDisabled={inProgress} />
          <ModalBody>
            <FormControl id="email">
              <FormLabel>
                <Trans>E-mail address</Trans>
              </FormLabel>
              <Input
                isDisabled={inProgress}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              {invalidEmail && (
                <FormHelperText>
                  <Trans>Please enter a valid e-mail address</Trans>
                </FormHelperText>
              )}
            </FormControl>
            {error && <Text color="danger">{error}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button
              isDisabled={invalidEmail}
              isLoading={inProgress}
              primary
              onClick={handleInvite}
            >
              <Trans>Invite</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
