import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export type CaseCount = {
  cases: number
}
export type CaseCountRequestModel = {
  fromInclusive: Date
  toInclusive: Date
  userUids: string[]
}

export const useCaseCount = (
  userUid: string,
): UseQueryResult<CaseCount, ResponseError> =>
  useQuery({
    queryKey: ['userCaseCount', userUid],
    queryFn: () =>
      request.post<{
        conversations: number
      }>('cases/count?api-version=3.3', {
        json: {
          fromInclusive: new Date(1970, 0, 1),
          toInclusive: new Date(),
          userFilters: [{ values: [userUid] }],
        },
      }),
  })
