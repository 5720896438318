import analytics from '@capturi/analytics'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  DatePicker,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useActionToast,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  ToastId,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { endOfDay, isToday, lastDayOfMonth } from 'date-fns'
import noop from 'lodash/noop'
import { useCaseCount } from 'pages/Organization/hooks/useCaseCount'
import { useConversationCount } from 'pages/Organization/hooks/useConversationCount'
import { useDeletedUsers } from 'pages/Organization/hooks/useDeletedUserActions'
import { Mode } from 'pages/Organization/types'
import React, { useRef, useState } from 'react'

type Options = {
  userUid: string
  name: string
}

type Props = Options & BaseModalComponentProps

const DeleteUserModal: React.FC<Props> = ({ onClose, userUid, name }) => {
  const today = new Date()
  const lastDateOfMonth = endOfDay(lastDayOfMonth(today))
  const toast = useToast()
  const toastIdRef = useRef<ToastId>()
  const [inProgress, setInProgress] = useState<boolean>()
  const [mode, setMode] = useState<Mode>('KeepNonPersonalAndAnonymize')
  const [toggleDatepicker, setToggleDatepicker] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(lastDateOfMonth)
  const { markUserForDeletion, cancelUserDeletion } = useDeletedUsers()

  const { enableText } = useFeatureFlags()

  const { data: conversationCount, isLoading: isConversationCountLoading } =
    useConversationCount(userUid)
  const { data: caseCount, isLoading: isCaseCountLoading } =
    useCaseCount(userUid)

  function closeToast(): void {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const successUndoToast = useActionToast({
    status: 'success',
    actionText: t`Undo`,
  })

  const submit = async (): Promise<void> => {
    setInProgress(true)
    try {
      await markUserForDeletion(userUid, date, mode)
      analytics.event('organization_users_userScheduledForDeletion', {
        mode,
      })
      onClose()
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`An error occurred during marking user for deletion. Try again.`,
        description: errorMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
      toastIdRef.current = successUndoToast({
        title: isToday(date)
          ? t`User will be deleted tonight`
          : t`User will be deleted on ${i18n.date(date, {
              dateStyle: 'long',
            })}.`,
        onActionClick: () => {
          cancelUserDeletion(userUid)
          closeToast()
        },
      })
    }
  }
  const handleMode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMode(e.target.value as Mode)
  }

  return (
    <Modal isOpen isCentered onClose={inProgress ? noop : onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Text>
              <Trans>Delete {name}</Trans>
            </Text>
          </ModalHeader>
          <ModalCloseButton isDisabled={inProgress} />
          {isConversationCountLoading || isCaseCountLoading ? (
            <Spinner mx="50%" />
          ) : (
            <ModalBody>
              {conversationCount?.conversations &&
              conversationCount.conversations > 0 ? (
                <Box>
                  <Text size="lg" fontWeight="medium" mb={2}>
                    <Trans>Audio</Trans>
                  </Text>
                  <Text fontWeight="medium" mb={2}>
                    <Trans>
                      User has {conversationCount?.conversations} conversations
                    </Trans>
                  </Text>
                  <Text fontSize="md" mb={3}>
                    <Trans>
                      What should happen to the user&apos;s conversations, when
                      the user is deleted?
                    </Trans>
                  </Text>

                  <RadioGroup
                    isDisabled={inProgress}
                    defaultValue="KeepNonPersonalAndAnonymize"
                  >
                    <Stack>
                      <Radio
                        disabled={inProgress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleMode(e)
                        }
                        value="KeepNonPersonalAndAnonymize"
                      >
                        <Text fontWeight="medium">
                          <Trans>Anonymise and keep statistics</Trans>
                        </Text>
                        <Text color="gray.600">
                          <Trans>
                            Conversations are anonymised by deleting the audio
                            and user information, but statistics remain and you
                            can still view trackers, comments etc. on the
                            conversations.
                          </Trans>
                        </Text>
                      </Radio>
                      <Radio
                        disabled={inProgress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleMode(e)
                        }
                        value="Permanently"
                      >
                        <Text fontWeight="medium">
                          <Trans>Delete everything</Trans>
                        </Text>
                        <Text color="gray.600">
                          <Trans>
                            All user information, audio and statistics are
                            deleted. Nothing remains.
                          </Trans>
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {enableText && (
                    <Box mt={5}>
                      <Text size="lg" fontWeight="medium" mb={2}>
                        <Trans>Email</Trans>
                      </Text>

                      <Text fontWeight="medium">
                        <Trans>
                          User has participated in {caseCount?.cases} email
                          threads
                        </Trans>
                      </Text>
                      <Text color="gray.600">
                        <Trans>
                          The user will have its email and name removed from all
                          emails where the user is present. All others will be
                          marked deleted by anonymizing the users information.
                        </Trans>
                      </Text>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Text fontWeight="medium" mb={2}>
                    <Trans>When do you want to delete this user?</Trans>
                  </Text>
                </Box>
              )}
              <Box
                textAlign="right"
                onClick={() => setToggleDatepicker(!toggleDatepicker)}
                mt={
                  conversationCount?.conversations &&
                  conversationCount.conversations > 0
                    ? 4
                    : 0
                }
              >
                <Text float="left">
                  <Trans>The user will be deleted at: </Trans>
                </Text>
                {i18n.date(date, { dateStyle: 'long' })}
              </Box>
              <Popover placement="bottom-end">
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Text
                        textAlign="right"
                        color="primary.500"
                        display="inline-block"
                        float="right"
                        fontSize="xs"
                        cursor="pointer"
                        fontWeight="medium"
                      >
                        <Trans>Change</Trans>
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent width="100%">
                      <span>
                        <PopoverArrow />
                      </span>
                      <PopoverBody px={0} pt={4}>
                        <Flex flexDir="column" alignItems="flex-start" gap={2}>
                          <Box>
                            <DatePicker
                              onSelectDate={(date: Date) =>
                                setDate(endOfDay(date))
                              }
                              value={date}
                              disabled={[{ before: new Date() }]}
                            />
                          </Box>
                          <Divider />
                          <Flex
                            justifyContent="flex-end"
                            width="100%"
                            px={4}
                            gap={2}
                          >
                            <Button onClick={onClose}>
                              <Trans>Cancel</Trans>
                            </Button>
                            <Button colorScheme="primary" onClick={onClose}>
                              <Trans>Apply</Trans>
                            </Button>
                          </Flex>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </ModalBody>
          )}
          <ModalFooter>
            <Flex justifyContent="end" gap={3}>
              <Button onClick={onClose} isDisabled={inProgress} size="md">
                <Trans>Cancel</Trans>
              </Button>
              <Button
                colorScheme="red"
                isLoading={inProgress}
                onClick={submit}
                size="md"
              >
                <Trans>Delete user</Trans>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default DeleteUserModal
