import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export type ConversationCount = {
  conversations: number
}

export type ConversationCountRequestModel = {
  fromInclusive: Date
  toInclusive: Date
  userUids: string[]
}

export const useConversationCount = (
  userUid: string,
): UseQueryResult<ConversationCount, ResponseError> =>
  useQuery({
    queryKey: ['userConversationCount', userUid],
    queryFn: async () =>
      await request.post<{
        conversations: number
      }>('conversations/count?excludeDeleted=false&api-version=3.3', {
        json: {
          fromInclusive: new Date(1970, 0, 1),
          toInclusive: new Date(),
          userUids: [userUid],
        },
      }),
  })
