import tracker from '@capturi/analytics'
import { CurrentUser } from '@capturi/core'
import { Button, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React, { ReactElement, useState } from 'react'

import { useFeatureFlags } from '@capturi/feature-flags'
import { PatchUser } from '../hooks/useUserActions'
import { Team, UserWithDeletionData } from '../types'
import TableHeader from './TableHeader'
import { teamTooltip } from './TableHeaderTooltips'
import NameCell from './cells/NameCell'
import SettingsCell from './cells/SettingsCell'
import TeamCell from './cells/TeamCell'
import { InviteUserFromEmailDialog } from './modals/InviteUserFromEmailModal'

type SortKey = 'name' | 'team'

type Props = {
  agents: UserWithDeletionData[]
  teams: Team[]
  currentUser: CurrentUser
  patchUser: PatchUser
  inviteUser: (userUid: string, email: string) => Promise<void>
  unassignUser: (userUid: string) => Promise<void>
  cancelUserDeletion: (userUid: string) => Promise<void>
}

const AgentsTable: React.FC<Props> = ({
  agents,
  currentUser,
  teams,
  inviteUser,
  patchUser,
  unassignUser,
  cancelUserDeletion,
}) => {
  const { hideInviteOptions } = useFeatureFlags()

  const [openEmailInviteModal] = useModal(InviteUserFromEmailDialog)
  const [isDrawerIdOpen, setIsDrawerIdOpen] = useState('')
  const toast = useToast({ duration: 10000 })
  const [sort, setSort] = useState<{
    key: SortKey
    direction: -1 | 1
  }>({
    key: 'name',
    direction: 1,
  })

  const sorted = agents.sort((a, b) => {
    switch (sort.key) {
      case 'team':
        if (!a.team?.name) return sort.direction
        if (!b.team?.name) return sort.direction * -1
        return a.team.name.localeCompare(b.team.name) * sort.direction
      default:
        return a.name.localeCompare(b.name) * sort.direction
    }
  })

  const renderHeader = (
    key: SortKey,
    name: string,
    tooltip?: React.ReactNode,
  ): ReactElement => {
    return (
      <TableHeader
        ariaLabel={name}
        tooltip={tooltip}
        onClick={() => {
          tracker.event('organizationPage_agents_headerSort', {
            key: key,
            directions: sort.direction,
          })
          setSort({ key: key, direction: sort.direction === 1 ? -1 : 1 })
        }}
        isActive={key === sort.key}
        direction={sort.direction}
      >
        {name}
      </TableHeader>
    )
  }

  const RenderRow = ({
    email,
    id,
    name,
    profileImage,
    title,
    team,
    inviteStatus,
    isScheduledForDeletion,
    lastTokenRefresh,
    mostRecentConversation,
  }: UserWithDeletionData): ReactElement => {
    const handleInviteUser = (): void => {
      openEmailInviteModal({
        userUid: id,
        currentEmail: email,
        name,
        invite: inviteUser,
        onSubmit: () => {
          toast({
            title: t`${name} is now invited, wuhuu!`,
            status: 'success',
          })
        },
      })
    }

    const rowClickHandler = (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ): void => {
      e.stopPropagation()
      if (
        (e.target as HTMLTableRowElement).tagName === 'TR' ||
        (e.target as HTMLTableRowElement).tagName === 'TD'
      )
        setIsDrawerIdOpen(id)
    }

    return (
      <Tr
        key={id}
        _hover={{ bgColor: 'gray.200' }}
        cursor="pointer"
        onClick={rowClickHandler}
      >
        <NameCell
          email={email}
          name={name}
          profileImage={profileImage}
          title={title}
          key={id}
          lastTokenRefresh={lastTokenRefresh}
          mostRecentConversation={mostRecentConversation}
        />
        <TeamCell
          team={team}
          teams={teams}
          userUid={id}
          currentUserUid={currentUser.id}
          patchUser={patchUser}
          name={name}
        />

        {!hideInviteOptions && (
          <Td w="5%">
            <Tooltip
              label={t`Undo scheduled deletion to invite user
              `}
              placement="top"
              hasArrow
              isDisabled={!isScheduledForDeletion}
            >
              <Box>
                <Button
                  onClick={handleInviteUser}
                  disabled={isScheduledForDeletion}
                >
                  {inviteStatus === 'unknown' ? 'Invite' : 'Resend invite'}
                </Button>
              </Box>
            </Tooltip>
          </Td>
        )}

        <SettingsCell
          isScheduledForDeletion={isScheduledForDeletion}
          inviteStatus={inviteStatus}
          userUid={id}
          teamUid={team?.uid}
          inviteUser={inviteUser}
          unassignUser={unassignUser}
          cancelUserDeletion={cancelUserDeletion}
          currentEmail={email}
          name={name}
          teamName={team?.name}
          patchUser={patchUser}
          title={title}
          setIsDrawerIdOpen={setIsDrawerIdOpen}
          isDrawerIdOpen={isDrawerIdOpen === id}
        />
      </Tr>
    )
  }

  return (
    <Table size="sm" width="100%" variant="bordered">
      <Thead>
        <Tr>
          <Th>{renderHeader('name', t`Name`)}</Th>
          <Th>{renderHeader('team', t`Team`, i18n._(teamTooltip))}</Th>
          {!hideInviteOptions && <Th w="5%" />}
          <Th w="5%" />
        </Tr>
      </Thead>
      <Tbody>{sorted.map(RenderRow)}</Tbody>
    </Table>
  )
}

export default AgentsTable
