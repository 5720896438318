import request, { ResponseError } from '@capturi/request'
import { UseMutationResult, useMutation } from '@tanstack/react-query'

export type MoveCasesPayload = {
  userUid: string
  newTeamUid: string
  fromDate: Date
}

export const useMoveCases = (): UseMutationResult<
  MoveCasesPayload,
  ResponseError,
  MoveCasesPayload,
  ResponseError
> => {
  return useMutation({
    mutationFn: async (payload: MoveCasesPayload) =>
      await request.post('cases/change-user-team?api-version=3.3', {
        json: {
          userUid: payload.userUid,
          newTeamUid: payload.newTeamUid,
          fromDate: payload.fromDate,
        },
      }),
  })
}
