import tracker from '@capturi/analytics'
import { Button, useToast } from '@capturi/ui-components'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React, { ReactElement, useState } from 'react'

import { PatchUser } from '../hooks/useUserActions'

type Props = {
  userUid: string
  onClose: () => void

  isOpen: boolean
  patchUser: PatchUser
  name: string
  title: string
  email: string
}

const EditUserDrawer: React.FC<Props> = ({
  userUid,
  onClose,
  isOpen,
  patchUser,
  ...p
}) => {
  const toast = useToast()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [name, setName] = useState(p.name)
  const [title, setTitle] = useState(p.title)
  const [email, setEmail] = useState(p.email)

  const handleSave = async (): Promise<void> => {
    try {
      setInProgress(true)
      tracker.event('organizationPage_userUpdated', {
        name: name !== p.name,
        title: title !== p.title,
        email: email !== p.email,
      })

      await patchUser(userUid, {
        title,
        name,
        email,
      })
      onClose()
      toast({
        title: t`Wuhuu, user updated`,
        status: 'success',
      })
    } catch (error) {
      let errMsg: null | string = null
      if (error instanceof Error) {
        errMsg = error.message
      }

      toast({
        title: t`Could not update the user's permission`,
        description: errMsg,
        status: 'error',
      })
    } finally {
      setInProgress(false)
    }
  }

  const renderTextField = (
    label: string,
    onChange: (val: string) => void,
    value: string,
  ): ReactElement => {
    return (
      <FormControl mt="2">
        <FormLabel mb="0" ml="1" htmlFor="name">
          {label}
        </FormLabel>
        <Input
          isDisabled={inProgress}
          id="name"
          value={value}
          onChange={(event) => onChange(event.currentTarget.value)}
          autoComplete="none"
        />
      </FormControl>
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={inProgress ? noop : onClose}
      placement="right"
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton isDisabled={inProgress} />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pr={10}
        >
          <Trans>Edit User</Trans>
        </DrawerHeader>
        <DrawerBody>
          {renderTextField(t`Title`, setTitle, title)}
          {renderTextField(t`Name`, setName, name)}
          {renderTextField(t`E-mail`, setEmail, email)}
          <Flex justifyContent="end" mt="8">
            <Button onClick={onClose} isDisabled={inProgress}>
              <Trans>Cancel</Trans>
            </Button>
            <Button primary ml="2" isLoading={inProgress} onClick={handleSave}>
              <Trans>Update</Trans>
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default EditUserDrawer
