import { Box, ListItem, Stack, Text, UnorderedList } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, msg } from '@lingui/macro'
import React from 'react'

export const teamLeadTooltip = msg`Team leads can only access conversations and data belonging to the selected teams.`

const RoleTooltip: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Text>
          <Trans>Owner</Trans>
        </Text>
        <Text>
          <Trans>
            Has access to all data and configuration in the platform, as well as
            the Organisation page and thus user administration.
          </Trans>
        </Text>
      </Box>
      <Box>
        <Text>
          <Trans>Administrator</Trans>
        </Text>
        <Text>
          <Trans>
            Has access to all data data, but do NOT have permission to invite
            users and configure the system.
          </Trans>
        </Text>
      </Box>
      <Box>
        <Text>
          <Trans>Team lead</Trans>
        </Text>
        <Text>{i18n._(teamLeadTooltip)}</Text>
      </Box>
      <Box>
        <Text>
          <Trans>User</Trans>
        </Text>
        <Text>
          <Trans>Users can only access their own conversations and data</Trans>
        </Text>
      </Box>
    </Stack>
  )
}

const PermissionTooltip: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Text>
          <Trans>Permissions</Trans>
        </Text>

        <Text>
          <Trans>
            Grant or remove the users permission to create, edit and delete the
            following functionalities:
          </Trans>
        </Text>
        <UnorderedList>
          <ListItem>
            <Trans>Dashboard</Trans>
          </ListItem>
          <ListItem>
            <Trans>Trackers</Trans>
          </ListItem>
          <ListItem>
            <Trans>Segment</Trans>
          </ListItem>
          <ListItem>
            <Trans>Score</Trans>
          </ListItem>
          <ListItem>
            <Trans>Library</Trans>
          </ListItem>
        </UnorderedList>
      </Box>
    </Stack>
  )
}
const StatusTooltip: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Text>
          <Trans>User is created, but not invited</Trans>
        </Text>
        <Text>
          <Trans>
            All employees for whom Capturi loads conversations are automatically
            created as users. However, these employees do not have access to the
            system unless being invited by an administrator or a team lead.
          </Trans>
        </Text>
      </Box>
      <Box>
        <Text>
          <Trans>The user is invited, but not yet active</Trans>
        </Text>
        <Text>
          <Trans>
            An admin or a team lead has invited the user to Capturi. However,
            the user has not yet signed in to Capturi and is not yet active in
            the system.
          </Trans>
        </Text>
      </Box>
      <Box>
        <Text>
          <Trans>User is active</Trans>
        </Text>
        <Text>
          <Trans>The user has signed into Capturi and is active.</Trans>
        </Text>
      </Box>
    </Stack>
  )
}

export const teamTooltip = msg`The team to which the user's conversations are linked`

export const roleTooltip = <RoleTooltip />
export const statusTooltip = <StatusTooltip />
export const permissionTooltip = <PermissionTooltip />
