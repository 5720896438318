import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import {
  MoveCasesPayload,
  useMoveCases,
} from 'pages/Organization/hooks/useMoveCases'
import React, { useState } from 'react'

type Options = {
  userUid: string
  newTeamUid: string
  teamName: string
}

type Props = Options & BaseModalComponentProps
const toTime = format("yyyy-MM-dd'T'HH:mm")

const moveCasesDialog: React.FC<Props> = ({
  onClose,
  userUid,
  newTeamUid,
  teamName,
}) => {
  const [date, setDate] = useState<string>(toTime(new Date()))
  const [moveCasesWithDate, setMoveCasesWithDate] =
    React.useState<string>('noDate')
  const { mutate: moveCases, isPending } = useMoveCases()
  const toast = useToast()

  const handleMoveCases = (payload: MoveCasesPayload): void => {
    moveCases(payload, {
      onSuccess: () => {
        toast({
          title: t`Moving the user’s tickets to ${teamName}. This operation might take a while.`,
          status: 'success',
        })
        onClose()
      },
      onError: (error) => {
        toast({
          title: t`Error while moving tickets`,
          status: 'error',
          description: error.message,
        })
      },
    })
  }
  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Text>
              <Trans>Move tickets to team {teamName}</Trans>
            </Text>
          </ModalHeader>
          <ModalCloseButton isDisabled={isPending} />
          <ModalBody>
            <RadioGroup
              id="move-cases"
              onChange={setMoveCasesWithDate}
              value={moveCasesWithDate}
            >
              <Stack>
                <Radio value="noDate" cursor="pointer">
                  <Trans>Move all tickets</Trans>
                </Radio>
                <Radio value="withDate" cursor="pointer">
                  <Trans>Move only tickets in specific time period</Trans>
                </Radio>
              </Stack>
            </RadioGroup>
            {moveCasesWithDate === 'withDate' && (
              <Flex mt={2} alignItems="center">
                <Text color="gray.600">
                  <Trans>Move all tickets since</Trans>
                </Text>
                <Input
                  type="datetime-local"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  isDisabled={isPending}
                  width="fit-content"
                  mx="2"
                  size="sm"
                />
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={isPending} secondary onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              ml="2"
              isLoading={isPending}
              primary
              onClick={() =>
                handleMoveCases({
                  userUid: userUid,
                  newTeamUid: newTeamUid,
                  fromDate:
                    moveCasesWithDate === 'noDate'
                      ? new Date('2020-01-01T07:00')
                      : new Date(date),
                })
              }
            >
              <Trans>Move tickets</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default moveCasesDialog
